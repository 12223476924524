import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Stock, StockData, StockPicture } from '../data/stock';

@Injectable()
export class StockService extends StockData {

// engineNumber: string;
// code: string;
// make: string;
// model: string;
// variant: string;
// year: string;
// color: string;
// mileage: string;

  private Stock = [
    { code : 's001', make: 'Honda',       model: 'Jazz',    variant: '1.2',         year: '2005', color: 'Silver',    mileage: '80 000',  priceWholeSale: 60000, priceRetail: 73000, engineNumber : 'BEBHJ-1004845'},
    { code : 's002', make: 'Hona',        model: 'Civic',   variant: 'Type-R',      year: '2010', color: 'White',     mileage: '120 000', priceWholeSale: 200000, priceRetail: 270000, engineNumber : 'BEBHJ-1004845'},
    { code : 's003', make: 'Volkswagen',  model: 'T-Cross', variant: '1.5 R-Line',  year: '2021', color: 'White',     mileage: '35 000',  priceWholeSale: 400000, priceRetail: 53000, engineNumber : 'BEBHJ-1005060'},
    { code : 's004', make: 'Volvo',       model: 'S40',     variant: '',            year: '2022', color: 'Red',       mileage: '41 000',  priceWholeSale: 600000, priceRetail: 75000, engineNumber : 'BEBF-2065923'},
    { code : 's005', make: 'BMW',         model: '330i',    variant: 'M',           year: '1995', color: 'Yellow',    mileage: '11 000',  priceWholeSale: 120000, priceRetail: 140000, engineNumber : 'BEBF-2065924'},
    { code : 's006', make: 'Tata',        model: 'Poefter', variant: 'Slow',        year: '2023', color: 'Brown',     mileage: '2 000',   priceWholeSale: 150, priceRetail: 99, engineNumber : 'BEAGJ-1104668'},
    { code : 's007', make: 'Mercedes',    model: 'GWagen',  variant: 'G63',         year: '2019', color: 'Black',     mileage: '238',     priceWholeSale: 2400000, priceRetail: 2650000, engineNumber : 'GCANM-2007244'},
    { code : 's008', make: 'Suzuki',      model: 'Swift',   variant: '1.2 GL',      year: '2019', color: 'Silver',    mileage: '62 000',  priceWholeSale: 120000, priceRetail: 140000, engineNumber : 'GCANM-2008372'},
    { code : 's009', make: 'Suzuki',      model: 'Swift',   variant: '1.2',         year: '2018', color: 'Gunmetal',  mileage: '143 000', priceWholeSale: 95000, priceRetail: 110000, engineNumber : 'GCAJH-1033319'},
    { code : 's010', make: 'Volkswagen',  model: 'Polo',    variant: 'Vivo',        year: '2012', color: 'Navy',      mileage: '358 000', priceWholeSale: 40000, priceRetail: 50000, engineNumber : 'GCAJH-1033310'},
  ];

  private StockPictures = [
    { fileName : 'Pic 1', engineNumber : 'BEBHJ-1004845'},
    { fileName : 'Pic 2', engineNumber : 'BEBHJ-1004845'},
    { fileName : 'Pic 1', engineNumber : 'BEBHJ-1005060'},
    { fileName : 'Pic 1', engineNumber : 'BEBF-2065923'},
    { fileName : 'Pic 1', engineNumber : 'BEBF-2065924'},
    { fileName : 'Pic 1', engineNumber : 'BEAGJ-1104668'},
    { fileName : 'Pic 1', engineNumber : 'GCANM-2007244'},
    { fileName : 'Pic 1', engineNumber : 'GCANM-2008372'},
    { fileName : 'Pic 1', engineNumber : 'GCAJH-1033319'},
    { fileName : 'Pic 1', engineNumber : 'GCAJH-1033310'},
   
  ]
  
  getStock(): Observable<any> {
    return observableOf(this.Stock);
  }

  getPicture(engineNumber: string): Observable<StockPicture>{
    return observableOf(this.StockPictures.find(x => x.engineNumber == engineNumber));
  }  

}


