import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CustomerContacts, CustomerData } from '../data/customers';

@Injectable()
export class CustomerService extends CustomerData {

  private customers = [
    { code: '001', name: 'Nick Jones', picture: 'assets/images/nick.png' },
    { code: '002', name: 'Eva Moor', picture: 'assets/images/eva.png' },
    { code: '003', name: 'Jack Williams', picture: 'assets/images/jack.png' },
    { code: '004', name: 'Lee Wong', picture: 'assets/images/lee.png' },
    { code: '005', name: 'Alan Thompson', picture: 'assets/images/alan.png' },
    { code: '006', name: 'Kate Martinez', picture: 'assets/images/kate.png' },
  ];

  private types = {
    mobile: 'mobile',
    home: 'home',
    work: 'work',
  };
  private contacts: CustomerContacts[] = [
    {customer: this.customers[0], type: 'mobile', value: '111 111 1111' },
    {customer: this.customers[1], type: 'mobile', value: '222 222 2222' },
    {customer: this.customers[2], type: 'mobile', value: '333 333 3333' },
    {customer: this.customers[3], type: 'mobile', value: '444 444 4444' },
    {customer: this.customers[4], type: 'mobile', value: '555 555 5555' },
    {customer: this.customers[5], type: 'mobile', value: '666 666 6666' },
  ];

  getCustomers(): Observable<any> {
    return observableOf(this.customers);
  }

  getContacts(customerCode: string): Observable<CustomerContacts> {
    return observableOf(this.contacts.find(x => x.customer.code == customerCode));
  }

}
