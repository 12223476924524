import { Observable } from 'rxjs';

export interface Dealer {
  name: string;
  code: string;
  franchise: string;
  groupId: number;

}

export abstract class DealerData {
  abstract getDealers(): Observable<Dealer[]>;
  
}
