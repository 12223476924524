import { Observable } from 'rxjs';

export interface Customer {
  name: string;
  code: string;
}

export interface CustomerContacts {
  customer: Customer;
  type: string;
  value: string;
}

export abstract class CustomerData {
  abstract getCustomers(): Observable<Customer[]>;
  abstract getContacts(customerCode: string): Observable<CustomerContacts>;
}
