import { of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DealerData } from '../data/dealers';

@Injectable()
export class DealerService extends DealerData {

  private Dealers = [
    { name: 'H100401-21 Tygerberg Honda CSI', code: 'H100401', franchise: 'H' },
    { name: 'Honda Bloemfontein HO', code: '100601H', franchise: 'H' },
    { name: 'Honda East London', code: '100801', franchise: 'Y' },
    { name: 'Motomid Honda', code: '101101', franchise: 'Z' },
    { name: 'Honda Auto PE', code: '102101', franchise: 'H' },
    { name: 'Honda Auto George', code: '102701', franchise: 'H' },
    { name: 'Honda Auto George', code: '102701', franchise: 'H' },
  ];

  
  getDealers(): Observable<any> {
    return observableOf(this.Dealers);
  }

}
