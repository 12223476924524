import { Observable } from 'rxjs';

export interface Stock {
  engineNumber: string;
  code: string;
  make: string;
  model: string;
  year: string;
  color: string;
  mileage: string;
  variant: string;
  priceWholeSale: number;
  priceRetail: number;
}

export interface StockPicture {
  fileName: string;
  engineNumber: string;
}

export abstract class StockData {
  abstract getStock(): Observable<Stock[]>;
  abstract getPicture(engineNumber: string): Observable<StockPicture>;  
}
